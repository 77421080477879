import React from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { apiCall, webMethodCall } from "../utilities/apiCall";
import {
  systemActivityTypes,
  accessLevels,
  enumSavedSearchPageTypes,
} from "../utilities/enums";
import { pdfjs } from 'react-pdf';
import { phoneTypes } from "./enums";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import DOMPurify from "dompurify";
import "react-confirm-alert/src/react-confirm-alert.css";
import CryptoJS from "crypto-js";
import { format } from "date-fns";
import moment from "moment";
import { SEND_EMAIL, globalMessages } from "../shared/globalization";
import { getDefaultSummaryFields } from "../../src/components/common/ModuleSummary/SummaryFieldsHelper";
import dragIcon from "../images/icon-drag.png";
import meatBallIcon from "../images/three-vdots.svg";
import StyledTooltip from "../shared/Tooltip";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const calendarMonts = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const numberWithCommas = function (number) {
  if (!number) number = 0;
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const decimalWithCommas = function (number = 0, minimumFractionDigits = 2, maximumFractionDigits = 2) {
  if (!number) number = 0;
  if(maximumFractionDigits<minimumFractionDigits) maximumFractionDigits = minimumFractionDigits;
  return number.toLocaleString(undefined, {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
};

export const isValidFileName = (filename)=>{
  var regex =/^[ A-Za-z0-9_!.~|'(){}\[\]&\-]*$/g;
  if(regex.test(filename))
  {
    return true;
  }
  else{
    return false;
  }
}

export const formatAmountWithCurrency = function (
  amount,
  isFormatWithDecimal = false
) {
  const currencySymbol = getTextFromHTML(getGlobalMessage("MMCurrency"));
  return isFormatWithDecimal
    ? amount < 0
      ? `(${currencySymbol}${decimalWithCommas(Math.abs(amount))})`
      : `${currencySymbol}${decimalWithCommas(amount)}`
    : amount < 0
    ? `(${currencySymbol}${numberWithCommas(Math.abs(amount))})`
    : `${currencySymbol}${numberWithCommas(amount)}`;
};

export const formatDecimalAmountWithCurrency = function (
  amount,
  currencySymbol = "",
  acceptNegative = false,
  minimumFractionDigits = 2
) {
  amount = typeof amount === "string" ? parseFloat(amount) : amount;

  if (amount < 0 && acceptNegative == false) {
    return `(${
      currencySymbol !== ""
        ? currencySymbol
        : getTextFromHTML(getGlobalMessage("MMCurrency"))
    }${decimalWithCommas(Math.abs(amount), minimumFractionDigits)})`;
  } else if (amount < 0 && acceptNegative) {
    return `-${
      currencySymbol !== ""
        ? currencySymbol
        : getTextFromHTML(getGlobalMessage("MMCurrency"))
    }${decimalWithCommas(Math.abs(amount), minimumFractionDigits)}`;
  } else {
    return `${
      currencySymbol !== ""
        ? currencySymbol
        : getTextFromHTML(getGlobalMessage("MMCurrency"))
    }${decimalWithCommas(amount, minimumFractionDigits)}`;
  }
};

export const formatDecimalAmountwithNoCurrency = function (amount) {
  amount = typeof amount === "string" ? parseFloat(amount) : amount;
  if (amount < 0) {
    return `(${decimalWithCommas(Math.abs(amount))})`;
  } else {
    return `${decimalWithCommas(amount)}`;
  }
};

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

// Does not include ipad.
export const isMobileOnly =
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

// is `window.top` page accessible (this is to support the pages in the CE)
export const isWindowTopAccessible = function () {
  try {
    const location = window.top.location.href; // eslint-disable-line no-unused-vars
    return true;
  } catch (e) {
    return false;
  }
};

export const updatePageTitle = function (title) {
  if (
    window.name &&
    isWindowTopAccessible() &&
    window.top.renameTab !== undefined
  ) {
    const iframeId = window.name;
    const tabID = iframeId.replace("_IFrame", "");
    window.top.renameTab(title, tabID);
  } else {
    document.querySelector("title").innerText = title;
  }
};
//This is required to call only for development environment referred in apiCall.js
export const API_AUTH_USERAUTHENTICATION_GET = `/services/Authentication/`;

export const getSessionValue = function (key) {
  try{
    return window.localStorage.getItem("MMClientVars")
      ? JSON.parse(window.localStorage.getItem("MMClientVars"))[key]
      : null;
  }
  catch{
    //Getting error in case of getting the token value for MSCP from PaynSaved Card Page.
    return null;
  }
};

export const addEmailNote = function (
  email,
  contactID,
  navigateToMailTo = true
) {
  apiCall(`/services/Crm/Activities/Note`, "POST", {
    ActivityType: systemActivityTypes.isEmail,
    RepID: getSessionValue("UserID"),
    ContactDetails: { ID: contactID },
    Note: getGlobalMessage(`EmailSentMMClientContact`),
    IsEmail: true,
    IsSystemNote: false,
  })
    .then((resp) => {
      if (resp.content.Status.toLowerCase() === "success" && navigateToMailTo) {
        window.open(`mailto:${email}`, "_blank");
      }
    })
    .catch((error) => {
      console.log("error while adding email to activity");
    });
};
export const handleGmailClick = (email, contactID,sendType = '') => {
  if (email) {
    //contactedit-m is for clicking mirabel email from contactedit Page
    let frompagetype = sendType === 'Mirabel' ? 'contactedit-m' : 'contactedit'
    openPage(
      `${process.env.PUBLIC_URL}/contactSearch/gmailtemplates/edit?frompage=${frompagetype}&id=${contactID}&email=${email}`,
      globalMessages(SEND_EMAIL),
      false,
      true
    );
  }
};
export const sendEmailTo = function (email, contactID) {
  if (email) {
    addEmailNote(email, contactID);
  }
};

export const addCall = function (contactID, Phone, LinkTypeDetails) {
  if (Phone&&LinkTypeDetails) {
    let jsonBody = {
      notificationType: "Calls",
      notificationJson: {
        Contacts: { ID: parseInt(contactID) },
        IsPrivate: false,
        Note: getGlobalMessage("PhoneCalledMMClient").replace("{0}", Phone),
        ActivityType: -1,
        IsCall: true,
        ActivityRep: { ID: -1 },
        MeetingDate: "",
        MeetingTime: "",
        IsAddtoTaskList: false,
        RepAssignedTo: { ID: -1 },
        IsSystemNote: false,
        SpecialNote:"CallbackFuture|5",//this is for to add additional time in  callback field
        NotificationSource:"MCR-PhoneLink"
      },
      bulkInsertNotificationJson: {},
    };
    /*webMethodCall("/intranet/Members/Customer/CustomersEdit.aspx/SaveNotification", "POST", jsonBody)*/
    apiCall(`/services/crm/activities/save`, "POST", jsonBody)
      .then((resp) => {
        let phoneType = phoneTypes[LinkTypeDetails.ID];
        window.open(`${phoneType}:${Phone}`, `_self`);
      })
      .catch((error) => {
        console.log("error while adding call:" + error);
      });
  }
};

export const getTextFromHTML = function (htmlContent) {
  let a = document.createElement("span");
  a.innerHTML = htmlContent;
  return a.textContent;
};
export const htmlEncode = (str) => {
  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
};
export const htmlDecode = (str) => {
  return String(str)
    .replace(/&amp;/g, "&")
    .replace(/&apos;/g, "'")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");
};
export const getGlobalMessage = function (code) {
  // Used `window.top` instead of `window` is to reuse `MMClientMessage` loaded in `Home.aspx` page
  const messageWindow = isWindowTopAccessible() ? window.top : window;
  return (
    (messageWindow.MMClientMessage && messageWindow.MMClientMessage[code]) || ""
  );
};

export const getDateFormat = function () {
  return getGlobalMessage("MMExtDateFormat") === "d/m/Y"
    ? "dd/MM/yyyy"
    : "MM/dd/yyyy";
};

export const getDateFormatType = () => {
  return getGlobalMessage("MMExtDateFormat") === "d/m/Y"
  ? "D/M/Y"
  : "M/D/Y";
}

export const getTimeFormat = function () {
  return "hh:mm aa";
};

export const getSaveDateFormat = function () {
  return "YYYY-MM-DD";
};
export const getSaveTimeFormat = function () {
  return "HH:mm";
};
export const openLink = function (value) {
  if (value) {
    if (!value.startsWith("http")) {
      value = "http://" + value;
    }
    window.open(value);
  }
};
// ex out put "2019-10-22"
export const getCurrentDate = (addDays = 0) => {
  let tempDate = new Date();
  tempDate.setDate(tempDate.getDate() + addDays);
  return tempDate.toISOString().slice(0, 10);
};

// ex out put "May 22, 2020"
export const getCurrentDateWithMonthName = (addDays = 0) => {
  let tempDate = new Date();
  return (
    calendarMonts[tempDate.getMonth()] +
    " " +
    tempDate.getDate() +
    ", " +
    tempDate.getFullYear()
  );
};

// ex out put "2019-10-22 15:33:22"
export const getCurrentDateTime = (addDays = 0) => {
  let dt = new Date();
  let dateString =
    dt.getFullYear() +
    "-" +
    (dt.getMonth() + 1) +
    "-" +
    dt.getDate() +
    " " +
    dt.getHours() +
    ":" +
    dt.getMinutes() +
    ":" +
    dt.getSeconds();
  return dateString;
};

export const isValidDate = function (dateStr, dateFormate = getDateFormat()) {
  dateStr = formatDate(dateStr, dateFormate);
  var regex = "";
  if (getDateFormat().toLowerCase() === "dd/mm/yyyy") {
    regex = /^((0?[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/(\d{4}))$/;
  } else if (getDateFormat().toLowerCase() === "mm/dd/yyyy") {
    regex = /^((0?[1-9]|1[0-2])\/(0?[1-9]|1[0-9]|2[0-9]|3[0-1])\/(\d{4}))$/;
  }
  if (!regex.test(dateStr)) {
    return false;
  }
  return true; // date is valid
};
/*Checks for the valid positive & negative decimal values */
export const isValidDecimal = function (value) {
  var regex = /^-?[0-9]\d*(\.\d+)?$/;
  if (value.toString().match(regex) == null) {
    return false;
  }
  return true;
};

export const purify = (value) => {
  return DOMPurify.sanitize(value);
}

// function is used to open a new page on new tab/window
export const openPage = function (
  nextPage,
  pageTitle,
  isQueryStrValEncoded = false,
  addTabAfterActiveTab = false,
  openInSameTab = false
) {
  try {
    var topPage = window.top;
    if (!isWindowTopAccessible() || topPage.openPageInNextTab === undefined) {
      window.open(nextPage, openInSameTab ? "_self" : "_blank");
    } else {
      if (openInSameTab)
        return topPage.openPage(
          nextPage,
          pageTitle,
          isQueryStrValEncoded,
          addTabAfterActiveTab
        );
      else
        return topPage.openPageInNextTab(
          nextPage,
          pageTitle,
          isQueryStrValEncoded,
          addTabAfterActiveTab
        );
    }
  } catch (e) {}
};

export const closePage = function () {
  try {
    var topPage = window.top;
    if (isWindowTopAccessible()) {
      return topPage.closeTab();
    }
  } catch (e) {}
};

export const getActiveTabIndex = function () {
  const topPage = window.top;
  if (isWindowTopAccessible()) {
    return topPage.App.tabpnlMain.items.indexOf(topPage.App.tabpnlMain.getActiveTab());
  }
}

export const closeTabByIndex = (index) => {
  const topPage = window.top;
  if (isWindowTopAccessible() && index > -1) {
    return topPage.App.tabpnlMain.remove(index);
  }
}

export const openBrowserWindow = function (
  url,
  title,
  options = "width=500,height=400,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no"
) {
  window.open(url, title, options);
};

export const getRelativeURL = function (relURL) {
  try {
    const location = window.location;
    return location.href.includes("localhost:3000")
      ? `${location.protocol}//${location.hostname}:3000${relURL}`
      : relURL;
  } catch (e) {}
};

export const getClientURL = function (clientAddress, relURL) {
  try {
    return `https://${clientAddress}${relURL}`;
  } catch (e) {}
};

export const isEmail = (email = null) => {
  //const regex = /^[\w\d](\.?[\w\d_-])*@[\w\d]+\.([\w]{1,6}\.)?[\w]{2,6}$/;
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const getContactAccessLevel = function (securitySettings) {
  const accessLevel =
    !securitySettings.Partner &&
    !securitySettings.SecurityOk &&
    !securitySettings.SecurityViewOK
      ? accessLevels.NoAccess
      : !securitySettings.Partner && securitySettings.SecurityViewOK
      ? accessLevels.ReadAccess
      : accessLevels.EditAccess;
  return accessLevel;
};

// This is especially used to pass the value to the DB
export const getIsoDate = (dateString) => {
  return formatDate(getMDYFormat(dateString), "mm/dd/yyyy");
};

export const formatPhone = function (phoneValue) {
   // Define a regular expression pattern to match continuous sequences of 10 digits
   const pattern = /\d{10}/g;

   // Find all matches of the pattern in the input string
   const matches = phoneValue.match(pattern);
 
   // Iterate through each match and format it as (xxx) xxx-xxxx
   if (matches) {
     matches.forEach((match) => {
       const formattedNumber = `(${match.slice(0, 3)}) ${match.slice(
         3,
         6
       )}-${match.slice(6)}`;
       phoneValue = phoneValue.replace(match, formattedNumber);
     });
   }
 
   return phoneValue;
};

export const getFileExtension = function (fileName) {
  return fileName
    .slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
    .toLowerCase();
};

export const replaceInvalidCharsInFileName = function (fileName) {
  return fileName.replace(/^\s+|\s+$/g, "");
};
//used to format in MM/dd/yyyy:hh:mm format
export const formatDateWithTime = (dateString) => {
  let date = new Date(dateString);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0) and noon (12)

  // Pad with leading zeros if necessary
  var formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  var formattedDate = date.getDate().toString().padStart(2, '0');
  var formattedHours = hours.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');

  var strTime = formattedHours + ':' + formattedMinutes + ' ' + ampm;
  return formattedMonth + "/" + formattedDate + "/" + date.getFullYear() + " " + strTime;
}
//used to format in MM/dd/yyyy or dd/MM/yyyy format
export const formatDate = function (
  dateString,
  format,
  daysToMinus = 0,
  showUntilCancelled = false
) {
  if (!format) {
    format = getDateFormat();
  }
  if (!dateString) {
    return "";
  }

  let dateValue = new Date(dateString);
  let dd = dateValue.getDate() - daysToMinus;
  let MM = dateValue.getMonth() + 1;
  const yyyy = dateValue.getFullYear();

  if (showUntilCancelled && yyyy === 9999) {
    return "Until Cancelled";
  }

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (MM < 10) {
    MM = `0${MM}`;
  }

  if (format.toLowerCase() === "dd/mm/yyyy") {
    return (dateValue = `${dd}/${MM}/${yyyy}`);
  } else if (format.toLowerCase() === "yyyy-mm-dd") {
    return (dateValue = `${yyyy}-${MM}-${dd}`);
  } else if (format.toLowerCase() === "mm-dd-yyyy") {
    return (dateValue = `${MM}-${dd}-${yyyy}`);
  } else {
    return (dateValue = `${MM}/${dd}/${yyyy}`);
  }
};

//Converts YYYY-MM-DD to MM/DD/YYYY
export const getMDYFormat = (dateString) => {
  try {
    if (dateString === "" || !dateString) {
      return "";
    }
    if (Object.prototype.toString.call(dateString) === "[object Date]") {
      let dd = dateString.getDate();
      let MM = dateString.getMonth() + 1;
      const yyyy = dateString.getFullYear();
      return `${MM}/${dd}/${yyyy}`;
    }
    dateString = dateString.split("T")[0]; // remove timestamp from date string
    //Firefox will unable to convert mm-dd-yyyy, It will throw exception for new Date("05-30-2022"), so will we're replacing hyphen (-) with slash (/)
    return moment(dateString.replace(/-/g, "/")).format("MM/DD/YYYY");
  } catch (error) {
    console.log("error occured in getMDYFormat method", error);
    return dateString;
  }
};

export const getDbDateFormat = () => {
  return "MM/dd/yyyy";
};
export const getYMDFormat = () => {
  return "yyyy-mm-dd";
};
export const formatDateAsString = (value) => {
  return format(formatDate(value, getDbDateFormat()), "MMMM D, YYYY");
};
export const formatLongDate = function (dateString, format = getDateFormat()) {
  if (
    dateString &&
    !(
      dateString.toString().indexOf("1900") > -1 ||
      dateString.toString().indexOf("1970") > -1 ||
      dateString.toString().indexOf("0001") > -1
    )
  ) {
    let dateValue = new Date(dateString);
    if (format === "mmmm-dd") {
      return dateValue.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else if (format === "mmm-yyyy") {
      return dateValue.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });
    }
    // else if(format==='mmm-dd-yyyy')
    else {
      return dateValue.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  } else return "";
};

export const uniqueCommaSeparatedString = function (value) {
  return [...new Set(value.split(",").filter(Boolean))].toString();
};
export const uniqueItems = function (arrayItems) {
  return [...new Set(arrayItems)];
};
export const formatTime = function (dateString) {
  let date = new Date();
  let from = "";
  if (dateString !== undefined) {
    date = new Date(dateString);
    from = "renderDate";
  }
  let hours = date.getHours();
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;

  minutes = minutes.toString();
  if (from === "") {
    if (parseInt(minutes[0]) > 2) {
      hours++;
      minutes = "00";
    } else minutes = "30";
  }
  let ampm = hours >= 12 && hours < 24 ? "PM" : "AM";
  let strTime =
    ("0" + (hours % 12 == 0 ? 12 : hours % 12)).slice(-2) +
    ":" +
    minutes +
    " " +
    ampm;
  return strTime;
};
export const convertTo24HourFormat = function(timeString) {
  const [time, period] = timeString.split(/([APMapm]{2})/);
  const [hours, minutes] = time.split(':').map(Number);
  let formattedHours = hours;
  if (period && period.toLowerCase() === 'pm' && hours < 12) {
    formattedHours += 12;
  } else if (period && period.toLowerCase() === 'am' && hours === 12) {
    formattedHours = 0;
  }
  
  return `${String(formattedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export const arrayOfTimes = function (timeInterval) {
  var x = timeInterval; // timeInterval; //minutes interval
  var times = []; // time array
  var tt = 0; // start time
  var ap = ["AM", "PM"]; // AM-PM

  //loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] =
      ("0" + (hh % 12 == 0 ? 12 : hh % 12)).slice(-2) +
      ":" +
      ("0" + mm).slice(-2) +
      " " +
      ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
  return times;
};

export const MessageTypes = {
  Error: "error",
  Info: "info",
  Success: "success",
  Warning: "warning",
};

export const showAlert = (msg) => {
  toastrAlert(msg);
};
export const dateFormat = function (dt, daysToMinus) {
  let today = new Date(dt);
  let dd = today.getDate() - daysToMinus;
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  if (getDateFormat().toLowerCase() === "dd/mm/yyyy") {
    today = `${dd}/${mm}/${yyyy}`;
  } else if (getDateFormat().toLowerCase() === "mm/dd/yyyy") {
    today = `${mm}/${dd}/${yyyy}`;
  }
  return today;
};

export const combineDateAndTime = (inputDate, inputTime) => {
  let datevalue = ""
  let timevalue = ""
  if (inputDate === "") {
    datevalue = "2000-01-01"
  } else {
    datevalue = inputDate
  }

  if (inputTime === "") {
    timevalue = "12:00 AM"
  } else {
    timevalue = inputTime
  }
  // Convert the time to 24-hour format
  const [time, modifier] = timevalue.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  }

  if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  // Combine date and time
  const combinedDateTime = `${datevalue}T${hours}:${minutes}:00`;
  return combinedDateTime
}
export const getplaceHolderContent = function (text, CharValue) {
  if (CharValue === "Plus") {
    CharValue = `&#65291;`;
  }
  let a = document.createElement("span");
  a.innerHTML = " " + CharValue + " " + text;
  return a.textContent;
};

export const getLabelForDynamicFields = function (
  text,
  isEditable,
  charLength
) {
  let str = "";
  // str = text.length > charLength ? (text.slice(0, charLength) + "...") : text;
  // if (isEditable) {
  //     return (getplaceHolderContent(text, "Plus"));
  // }
  return text;
};

export const toastrAlert = (msg) => {
  toastr.options = {
    // progressBar: true,
    newestOnTop: false,
    closeButton: true,
    preventDuplicates: true,
    positionClass: "toast-top-center",
    timeOut: msg.msg_type === MessageTypes.Error ? 6000 : 3000,
    extendedTimeOut: 5000,
    ...msg,
  };

  switch (msg.msg_type) {
    case MessageTypes.Error: {
      const title = msg.title === undefined ? "Error" : msg.title;
      toastr.error(msg.msg_text, title);
      break;
    }
    case MessageTypes.Success: {
      const title = msg.title === undefined ? "Success" : msg.title;
      toastr.success(msg.msg_text, title);
      break;
    }
    case MessageTypes.Warning: {
      const title = msg.title === undefined ? "Warning" : msg.title;
      toastr.warning(msg.msg_text, title);
      break;
    }
    case MessageTypes.Info: {
      const title = msg.title === undefined ? "Info" : msg.title;
      toastr.info(msg.msg_text, title);
      break;
    }
    default: {
    }
  }
};
export const showConfirm = (msg) => {
  return new Promise(function (resolve, reject) {
    confirmAlert({
      title: msg.title,
      message: msg.msg_text,
      buttons: [
        {
          label: msg.okText ? msg.okText : "Yes",
          onClick: () => {
            resolve(true);
          },
        },
        {
          label: msg.cancelText ? msg.cancelText : "No",
          onClick: () => {
            resolve(false);
          },
        },
      ],
    });
  });
};
export const findDistinct = (
  inputArray,
  duplicateKey,
  key,
  value,
  sortByValue = false
) => {
  let duplicateChecker = [];
  let newArray = [];
  if (value) {
    for (var i = 0; i < inputArray.length; i++) {
      if (duplicateChecker.indexOf(inputArray[i][duplicateKey]) < 0) {
        duplicateChecker.push(inputArray[i][duplicateKey]);
        newArray.push({
          [key]: inputArray[i][key],
          [value]: inputArray[i][value],
        });
      }
    }
  } else {
    for (var i = 0; i < inputArray.length; i++) {
      if (
        duplicateChecker.indexOf(inputArray[i][duplicateKey]) < 0 &&
        inputArray[i][duplicateKey] !== -1
      ) {
        duplicateChecker.push(inputArray[i][duplicateKey]);
        newArray.push({ [key]: parseInt(inputArray[i][key]) });
      }
    }
  }
  return sortByKey(newArray, sortByValue ? value : key, "asc");
};
export const sortByKey = (jsonArray, sortKey, ascDesc = "asc") => {
  if (ascDesc == "asc") {
    return jsonArray.sort((prim, sec) => {
      return prim[sortKey] - sec[sortKey];
    });
  } else {
    return jsonArray.sort((prim, sec) => {
      return sec[sortKey] - prim[sortKey];
    });
  }
};
export const sortStringByKey = (jsonArray, sortKey, ascDesc = "asc") => {
  if (ascDesc.toLowerCase() == "asc") {
    return jsonArray.sort(function (a, b) {
      var x = a[sortKey];
      var y = b[sortKey];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  } else {
    return jsonArray.sort(function (a, b) {
      var x = a[sortKey];
      var y = b[sortKey];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  }
};

export const sortStringBySubKey = (jsonArray, sortKey, ascDesc = "asc") => {
  var newKey = sortKey.split('.');
  if (ascDesc.toLowerCase() == "asc") {
    return jsonArray.sort(function (a, b) {
      var x = newKey[1]? a[newKey[0]][newKey[1]] : a[newKey[0]];
      var y = newKey[1]? b[newKey[0]][newKey[1]] : b[newKey[0]];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  } else {
    return jsonArray.sort(function (a, b) {
      var x = newKey[1]? a[newKey[0]][newKey[1]] : a[newKey[0]];
      var y = newKey[1]? b[newKey[0]][newKey[1]] : b[newKey[0]];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  }
};

export const isEmptyObject = (obj) => {
  var result;

  if (obj)
    result = Object.entries(obj).length === 0 && obj.constructor === Object;
  else result = true; //Return true if the object is undefined or null

  return result;
};

export const capitalizeWords = (str) => {
  return (
    str &&
    str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
  );
};

//getting dynamically labels and tooltips
export const getDynamicFieldsData = (fieldsData, fieldName) => {
  if (fieldsData.page == null) {
    const res = fieldsData.find((obj) => obj.Code === fieldName);
    if (res) {
      return {
        label: res.Label,
        tooltip: res.Tooltip,
        isVisible: res.IsVisible,
      };
    } else if (res === undefined) {
      return {
        label: fieldName,
      };
    }
  }
};

export const encodeXMLReserved = (extract) => {
  return extract
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;");
};

export const getContactType = (customerTypeID) => {
  switch (customerTypeID) {
    case 1:
    case "1":
      return "Company/Advertiser";
    case 2:
    case "2":
      return "Production Contact";
    case 3:
    case "3":
      return "Agency Contact";
    default:
      return "";
  }
};

export const getRandomColors = () => {
  const letters = "BC589".split(""); // Use "BCDEF" for lighter colors and "56789" for brighter colors.
  let color = "#";
  for (let i = 0; i < 3; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

export const repColorCodes = [
  "#469FBB",
  "#f80c9c",
  "#FF5733",
  "#C81549",
  "#0B831C",
  "#A8B41C",
  "#0C475A",
  "#900C3F",
  "#27AE60",
  "#9064DF",
];

export const paymentMethodColorCodes = [
  "#D15E5E",
  "#006FCF",
  "#866AD1",
  "#F7941F",
  "#D52F92",
  "#CF7E36",
  "#6D9E2E",
  "#CC66D0",
  "#219653",
];
export const createTextLinks = (text) => {
  const replaceFunc = (match, space, url) => {
    var hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return (
      space +
      '<a contentEditable="false" href="' +
      hyperlink +
      '" target="_blank">' +
      url +
      "</a>"
    );
  };
  return (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    replaceFunc
  );
};

export const getJsonPropertyOrCode = (field) => {
  return field ? field.JSONProperty || field.Code : "";
};

export const saveNotification = (
  notificationType,
  orderID,
  isPickUpFrom,
  pickUpFromDisplay
) => {
  let isPickUpFromDesc = "";
  if (isPickUpFrom === true) {
    notificationType = "PickUpFrom";
    isPickUpFromDesc = pickUpFromDisplay;
  }
  apiCall(
    `/services/home/notifications/${orderID}/${notificationType}`,
    "POST",
    isPickUpFromDesc
  )
    .then((resp) => {
    })
    .catch((error) => {
      console.log(`Error while sending notification and error is: ${error}`);
    });
};

export const getNearestSmallNumber = (dynamicFields, myValue, parentCode) => {
  const fields = dynamicFields.filter(
    (item) => item.ParentCode === parentCode && item.InputType !== "Button"
  );
  fields.sort((a, b) => {
    return a.SortOrder - b.SortOrder;
  });
  const arr = fields.map((item) => {
    return item.SortOrder;
  });
  const number = arr.reverse().find((e) => e < myValue);
  return number;
};

export const getYesOrNo = (value) => {
  if (value == 1 || value === true) {
    return "Yes";
  } else if (value == 0 || value === false) {
    return "No";
  } else {
    return value;
  }
};
export const getHeaderNameWithSortIcons = (
  Header,
  accessor,
  sortFilter,
  isSortIconsRequired,
  ListOfDynamicInfoIcons = [], // List of dynamic info icons
  isShowIconInfo,
  visibleDragIcon,
  meatballMenuOptions = [],
  meatBallColumn = "",
  setMeatBallColumn,
) => {
  return (
    <div className="overflow-ellipsis flex">
      {meatballMenuOptions?.length > 0 &&
        meatballMenuOptions?.some((option) => option.accessor === accessor) && (
          <>
            <img
              src={meatBallIcon}
              alt="Meat Ball Menu Options Icon"
              onClick={(e) => {
                e.stopPropagation();
                setMeatBallColumn(meatBallColumn === accessor ? "" : accessor);
              }}
              className="meatball-menu-icon"
            />
          </>
        )}

      <div className="header-name">
        {visibleDragIcon && <img src={dragIcon} className="icon-drag" />}
        {Header}
        {isShowIconInfo && <span title={Header} className="iconInfo"></span>}
        {ListOfDynamicInfoIcons?.map(
          (icon) =>
            icon.accessor === accessor && (
              <span className="grid-dynamic-info-icon">
                <StyledTooltip
                  content={icon.info}
                  direction="up"
                  background="#fff"
                  zIndex={99999}
                >
                  <i className="label-info-icon field-info-icon"></i>
                </StyledTooltip>
              </span>
            )
        )}
      </div>

      {isSortIconsRequired &&
        sortFilter &&
        !isEmptyObject(sortFilter) &&
        (sortFilter.Property === accessor ? (
          <span
            className={
              Header == " " ? "empty-sort-single-icon" : "sort-single-icon"
            }
          >
            {sortFilter.Direction === "ASC" ? <FaSortUp /> : <FaSortDown />}{" "}
          </span>
        ) : (
          <div
            className={
              Header == " " ? "empty-sort-double-icons" : "sort-double-icons"
            }
          ></div>
        ))}
    </div>
  );
};
export const crmEncryptDecryptKey = "$%x@#~$a";
export const authEncryptDecryptKey = "20SA16ED";
export const enkey = "C6mL09K3Y";
export const encryptByDES = (message, key) => {
  try {
    let keyHex = CryptoJS.enc.Utf8.parse(key);
    let ivHex = CryptoJS.enc.Utf8.parse(key);
    keyHex = CryptoJS.SHA1(keyHex);
    ivHex = CryptoJS.SHA1(ivHex);
    // CryptoJS use CBC as the default mode, and Pkcs7 as the default padding scheme
    var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
      mode: CryptoJS.mode.CBC,
      iv: ivHex,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  } catch {
    return "";
  }
};
export const decrypt = (message, key) => {
  try {
    let keyHex = CryptoJS.enc.Utf8.parse(key);
    let ivHex = CryptoJS.enc.Utf8.parse(key);
    keyHex = CryptoJS.SHA1(keyHex);
    ivHex = CryptoJS.SHA1(ivHex);
    var decrypted = CryptoJS.DES.decrypt(message, keyHex, {
      mode: CryptoJS.mode.CBC,
      iv: ivHex,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch {
    return "";
  }
};

export const getAggregateAmount = (values, currencyCheck) => {
  let showCurrencySymbol,
    currency = "";
  const sum = values.reduce((total, cur) => {
    const isNegative = cur.includes("(") || cur.includes(")");

    let isDotSeperator =
      (1.1).toLocaleString().substring(1, 2) === "." ? true : false;
    let parsedVal = "0";
    if (isDotSeperator) {
      parsedVal = cur.replace(/[',','(',')']/g, "");
    } else {
      parsedVal = cur.replace(/[\.'()]/g, "").replace(/,/g, ".");
    }
    let parsed;
    if (currencyCheck) {
      showCurrencySymbol = parsedVal.includes(
        getTextFromHTML(getGlobalMessage("MMCurrency"))
      );
      parsed = parseFloat(
        parsedVal.replace(getTextFromHTML(getGlobalMessage("MMCurrency")), "")
      );
    } else {
      let temp=cur.trim();
      showCurrencySymbol = temp[0] && isNaN(temp[0]) ? true : false;
      currency = showCurrencySymbol ? temp[0] : "";
      parsed = showCurrencySymbol
        ? parseFloat(parsedVal.replace(temp[0], ""))
        : parseFloat(
            parsedVal.replace(
              getTextFromHTML(getGlobalMessage("MMCurrency")),
              ""
            )
          );
    }
    parsed = isNegative ? -parsed : parsed;
    return parsed + total;
  }, 0.0);
  return showCurrencySymbol
    ? formatDecimalAmountWithCurrency(sum, currency)
    : sum < 0
    ? "(" + decimalWithCommas(Math.abs(sum)) + ")"
    : decimalWithCommas(Math.abs(sum));
};

export const getReorderedList = (list, oldIndex, newIndex) => {
  const result = Array.from(list);
  result.splice(newIndex, 0, result.splice(oldIndex, 1)[0]);
  return result;
};

export const isAllPropertiesEmpty = (object) => {
  return Object.values(object).filter(Boolean).length == 0;
};

export const contactEditProposalOrOrderSecurity = async (id, isAdmin, type) => {
  let readOnly = false;
  await apiCall(`/services/production/security/${id}`).then(
    (prodSecurityData) => {
      if (prodSecurityData.content.Status === "Success") {
        if (type === "proposal") {
          if (
            (prodSecurityData.content.Data.CanCreateProposal === true &&
              prodSecurityData.content.Data.IsAuthorizedToEdit === true) ||
            isAdmin === true
          ) {
            readOnly = false;
          } else {
            readOnly = true;
          }
        } else if (type === "order") {
          if (
            (prodSecurityData.content.Data.CanCreateOrder === true &&
              prodSecurityData.content.Data.CreditAndOverLimitCheck === true &&
              prodSecurityData.content.Data.IsAuthorizedToEdit === true) ||
            isAdmin === true
          ) {
            readOnly = false;
          } else {
            readOnly = true;
          }
        }
      }
    }
  );
  return readOnly;
};

export const isFieldEmpty = (field) => {
  if (field === "" || field === null || field === undefined) {
    return true;
  }
  return false;
};
export const GoogleOAuthUrl = (googleAuthurl, scope, clientId, redirectUrl,type) => {
  const state = encryptByDES(
    `${getSessionValue("ClientID")}~${getSessionValue("UserID")}~${type}`,
    authEncryptDecryptKey
  );
  return `${googleAuthurl}?scope=${scope}&client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code&access_type=offline&approval_prompt=force&state=${state}`;
};

export const OutlookOAuthUrl = (
  outlookAuthurl,
  scope,
  clientId,
  redirectUrl,
  code_challenge
) => {
  const state = encryptByDES(
    `${getSessionValue("ClientID")}~${getSessionValue("UserID")}`,
    authEncryptDecryptKey
  );
  return `${outlookAuthurl}?scope=${scope}&client_id=${clientId}&redirect_uri=${redirectUrl}&response_mode=form_post&response_type=code&client_info=1&code_challenge=${code_challenge}&code_challenge_method=plain&prompt=select_account&nonce=678910&state=${state}`;
};

export const DropboxOAuthUrl = (
  dropboxOAuthUrl,
  clientId,
  redirectUrl
) =>{
  const state = encryptByDES(
    `${getSessionValue("ClientID")}~${getSessionValue("UserID")}`,
    authEncryptDecryptKey
  );
  return `${dropboxOAuthUrl}?client_id=${clientId}&token_access_type=offline&approval_prompt=force&force_reapprove=true&redirect_uri=${redirectUrl}&response_type=code&state=${state}`;
}

export const convertToDecimal = (value, digits) => {
  if (isFieldEmpty(value)) {
    return value;
  }
  return parseFloat(value).toFixed(digits);
};

// converts strings of format IE=299~IE=298~IE=100~IE=128~", SW=The Magazine Manager~SW=Hari inc~" to comma seperated values.
export const getCommaSeparatedString = (originalStr) => {
  if (originalStr) {
    originalStr = originalStr.replace(/~|IE|EE|SW/gi, "").replace(/=/g, ",");
    if (originalStr[0] === ",") {
      return originalStr.slice(1);
    }
  }
  return originalStr;
};
export const addSiteTimeAddToDate = (date, minutes) => {
  return new Date(new Date(date).setMinutes(date.getMinutes() + minutes));
};

export const getValueFromIcode = (iCodeStr) => {
  const regex = /^[A-Za-z]+=.*~$/ ;
  
  // Sample value IE=1~ and this function returns 1.
  // Check if the input value contains a pattern like =number~ or =~ the extract the code, else return the input value
  if (iCodeStr && regex.test(iCodeStr)) {
    let arr = [];
    let displayValue = iCodeStr.slice(0, -1); // To remove the trailing pipe
    displayValue.split("~").map((valuePair) => {
      const [iCode, value] = valuePair.split("=");
      arr.push(value);
    });
    return arr.toString();
  }
  return iCodeStr;
};

export const getIcodeFromArray = (arr) => {
  let str = "";
  arr.map((x) => (str += `IE=${x}~`));
  return str;
};

export const getArrayFromIcode = (value) => {
  if (value) {
    return value
      .toString()
      .match(/-?\d+/g)
      .toString()
      .split(",")
      .map((x) => +x);
  }
  return value;
};
export const getArrayFromIcodeStrings = (value) => {
  if (value) {
    return value
      .toString()
      .match(/([A-Za-z]+)/g)
      .filter(x => (x != 'IE' && x != 'EE'));
  }
  return value;
};

export const getStartDateOfMonth = (d) => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getEndDateOfMonth = () => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
export const EmailStatusTitle = (title) => {
  if (title === "Invalid") {
    return "The email verification service has determined this is a bad/unused email address.";
  } else if (title === "Valid") {
    return "This email address is valid and has been verified.";
  } else if (title === "Unknown" || title === "NotInitiated") {
    return "The email verification service was not able to determine if this is a valid email address.";
  } else if (title === "NotEnabled") {
    return "The email verification service has not been enabled on your site.";
  } else {
    return "";
  }
};

export const getMonthStartAndEndDates = (date, type) => {
  if (type == "start") return `${date.getFullYear()}-${date.getMonth() + 1}-01`;
  else if (type == "end") {
    let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return `${endDate.getFullYear()}-${
      endDate.getMonth() + 1
    }-${endDate.getDate()}`;
  }
};

export const subtractHoursFromDate = (date, hours) => {
  return new Date(new Date(date).setHours(date.getHours() - hours));
};
export const dayOfYear = (date) => {
  return Math.floor(
    (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
  );
};

export const isEmpty = (str) => {
  if (str === null || str === undefined || str === "") {
    return true;
  }
  return false;
};
export const getShortName = (name, isFirstNameLastInNameProperty = true) => {
  if (name) {
    const arrayNames = name.split(" ");
    let FirstNameIndex = 1;
    if (arrayNames.length >= 2) {       // like ["Last","Support,","First","Tech"]
        const index = arrayNames.findIndex((item) => item.includes(','));
        if(index>-1)
        {
        FirstNameIndex = index+1;
        }
        
      if (isFirstNameLastInNameProperty) {
        return (                                                  //for ["Last","Support,","First","Tech"] returns FL
          arrayNames[FirstNameIndex].substr(0, 1).toUpperCase() +
          arrayNames[0].substr(0, 1).toUpperCase()
        );
      } else {
        return (
          arrayNames[0].substr(0, 1).toUpperCase() +
          arrayNames[FirstNameIndex].substr(0, 1).toUpperCase()
        )
      }
    } else if (arrayNames.length == 1) {
      return arrayNames[0].substr(0, 1).toUpperCase();
    }
  } else {
    return "";
  }
};

//to map SalesRep names with their respective colors (for Sales Module)
export const salesRepColorCodes = (input, key1, key2) => {
  return [
    {"RepName": input[key1],
    [key2 ]: input[key2]? input[key2] : null}
];
};

//for contactHeader
export const mapsalesRepsWithColorCodes = (inputArray, key1, key2 = null) => {
  let uniqueValues = [...new Set(inputArray.map((item) => item[key1]))];

  return uniqueValues.map((value, index) => {
    const matchingItem = inputArray.find(item =>
      item[key1] === value);

    return {
      RepName: matchingItem[key1],
      [key2 || 'Color']: matchingItem[key2] ? matchingItem[key2] : repColorCodes[index % 9],
    };
  });
};

//for Production Notes Creator Name bubble
export const salesRepsWithNameColorCodes = (inputArray, key1, key2 = null,key3=null) => {
  let arr = [];
  arr = key2
    ? [...new Set(inputArray.map((item) => item[key1][key2]))]
    : [...new Set(inputArray.map((item) => item[key1]))];

  return arr.map((x, index) => {
    let repColor = inputArray.find((n)=> n.Creator.Name==x) ? inputArray.find((n)=> n.Creator.Name==x).Creator.Color : null;
    return { RepName: x, color: repColor ? repColor: repColorCodes[index % 9] };
  });
};
//for Activities Search
export const mapRepsColors = (inputArray, key1, salesReps) =>{
  let arr = [];
  arr = [...new Set(inputArray.map((item) => item[key1]))];
  return arr.map((x, index) => {
    let repColor = salesReps? salesReps.find((r)=> x === r.Display.split(',').reverse().join(' ').trim())?.Color:null;
    return { RepName: x, color: repColor ? repColor : repColorCodes[index % 9] };
  });
}

export const salesRepsWithColorCodes = (inputArray, key1, key2 = null) => {
  let arr = [];
  arr = key2
    ? [...new Set(inputArray.map((item) => item[key1][key2]))]
    : [...new Set(inputArray.map((item) => item[key1]))];

  return arr.map((x, index) => {
    return { RepName: x, color: repColorCodes[index % 9] };
  });
};
export const partnerRepsWithColorCodes = (inputArray, salesRep=null) => {
  let arr = [];
  arr = [...new Set(inputArray.split(",").map((item) => item.trim()))];

  return arr.map((x, index) => {
    let repColor = salesRep? salesRep.find((r)=> x === r.Display.split(',').reverse().join(' ').trim())?.Color:null;
    return { RepName: x, color: repColor? repColor: repColorCodes[index % 9] };
  });
};

// salesRepsWithColorCodes =[{RepName:'',color:'#4b89dc'},{}]
export const getSalesRepsName = (
  salesRepName,
  salesRepsWithColorCodes,
  isfirstNameLastProperty = true,
  isNameIncludeWithHeader = false,
  titlewithHeader = undefined,
  isPartnerRep = false
) => {
  if (salesRepName && salesRepsWithColorCodes.length > 0) {
    const colorCode = salesRepsWithColorCodes.find((data) => {
      return data.RepName === salesRepName;
    });
    return (
      <div
        className="ml-1"
        title={
          titlewithHeader ? titlewithHeader + " " + salesRepName : salesRepName
        }
      >
        {colorCode && (
          <div
            className="rep-circle"
            style={{ background: colorCode ? (colorCode.Color ? colorCode.Color:colorCode.color) : "#4b89dc" }}
          >
            {getShortName(salesRepName, isfirstNameLastProperty)}
          </div>
        )}
        {!isPartnerRep && isNameIncludeWithHeader && (
          <div className="pl-2 rep-circle-name overflow-ellipsis">
            {salesRepName}
          </div>
        )}
      </div>
    );
  }
};

  /**
   * This method returns missing search fields which are present in DB but not in existing saved search setup. It is being used in order search & module criteria page.
   * @param {*} mongoData
   * @returns
   */
   export const getMissingSearchFields = (mongoData, searchFieldConfig) => {
    //Get all field lists available for current productType
    const fieldsListForCurrentProductType = Object.keys(searchFieldConfig)
      .map((key) => searchFieldConfig[key].FieldsList)
      .flatMap((item) => item);

    //Get list of fieldLists which are already saved in Mongo
    const MongoFieldsList = mongoData.Sections.map((eachSection) =>
      eachSection.fieldsList.map((item) => item.ControlID)
    ).flatMap((item) => item);

    //If field is present in fieldsListForCurrentProductType and not present in MongoFieldsList, then we have to display that field in fieldsList
    const MissedFields = fieldsListForCurrentProductType.filter(
      (item) => MongoFieldsList.indexOf(item.ControlID) === -1
    );
    return MissedFields;
  }

export const isTask = function (taskID) {
  if (taskID <= 0 || taskID == null) {
    return false;
  } else {
    return true;
  }
};

export const summaryDataTypes = {
  WholeNumber: 1,
  DecimalNumberWithNoCurrency: 2,
  DecimalNumberWithCurrency: 3,
  Percentage: 6,
  WholeNumberWithCurrency: 7,
};

export const summaryFinalFormatedData = (
  obj,
  response,
  PageTypeId,
  done = "",
  arrived = "",
  summaryDetails
) => {
  let finaldata = [];
  if (response && response.SummaryFields && response.SummaryFields.length > 0) {
    finaldata = response.SummaryFields.filter((ele) => ele.IsSelected === true);
  } else {
    finaldata = getDefaultSummaryFields(parseInt(PageTypeId)).filter(
      (ele) => ele.IsSelected === true
    );
  }
  const displayValueAllocate = (ele) => {
    const { DataIndex, type } = ele;
    switch (type) {
      case summaryDataTypes.DecimalNumberWithCurrency:
        return formatDecimalAmountWithCurrency(obj.summaryDetails[DataIndex]);
      case summaryDataTypes.DecimalNumberWithNoCurrency:
        return formatDecimalAmountwithNoCurrency(obj.summaryDetails[DataIndex]);
      case summaryDataTypes.WholeNumberWithCurrency:
        return formatAmountWithCurrency(obj.summaryDetails[DataIndex]);
      case summaryDataTypes.Percentage:
        return parseInt(PageTypeId) === enumSavedSearchPageTypes.OrderSearch &&
          (DataIndex !== "Total" || DataIndex !== "ProdCharges")
          ? (obj.summaryDetails["Total" + ele.Title.split(" ").join("")] *
              100) /
              obj.summaryDetails["Total"] >=
              99 &&
            (obj.summaryDetails["Total" + ele.Title.split(" ").join("")] *
              100) /
              obj.summaryDetails["Total"] <
              100
            ? "99%"
            : numberWithCommas(
                (obj.summaryDetails["Total" + ele.Title.split(" ").join("")] *
                  100) /
                  obj.summaryDetails["Total"]
              ) + "%"
          : `${obj.summaryDetails[DataIndex]}%`;
      default:
        return numberWithCommas(obj.summaryDetails[DataIndex]);
    }
  };
  if (done && arrived) {
    finaldata.map((field) => {
      const { DataIndex, ColorCode } = field;
      if (DataIndex === "TotalAdArrived" && field.SummaryFieldID === 2) {
        field["Title"] = arrived;
        field["ColorCode"] = field["ColorCode"]
          ? ColorCode
          : summaryDetails.find((x) => x.title === arrived).color;
      } else if (DataIndex === "TotalAdDone" && field.SummaryFieldID === 3) {
        field["Title"] = done;
        field["ColorCode"] = field["ColorCode"]
          ? ColorCode
          : summaryDetails.find((x) => x.title === done).color;
      }
      return field;
    });
  }
  if (obj.summaryDetails) {
    finaldata = finaldata.map((ele) => {
      const DisplayValue = displayValueAllocate(ele);
      return {
        ...ele,
        DisplayValue,
        IsSelected: false,
      };
    });
  }
  return finaldata;
};

export function IsSaUser() {
  return (
    getSessionValue("Email") &&
    getSessionValue("Email").includes("sa@magazinemanager.com")
  );
}
export function IsTsUser() {
  return (
    getSessionValue("Email") &&
    getSessionValue("Email").includes("techsupport@magazinemanager.com")
  );
}

export const creditCardPaymentMethodDdData = [
  {
      Display: "Visa",
      Value: "Visa",
  },
  {
      Display: "Master Card",
      Value: "Master Card",
  },
  {
    Display: "Discover",
    Value: "Discover",
  },
  {
      Display: "Amex",
      Value: "Amex",
  },
  {
      Display:"ACH/Elec",
      Value:"ACH/Elec",
  }
];

export const quickBooksPaymentMethodData = [
  {
    Display: "ACH/Elec",
    Value: "ACH/Elec",
  },
  {
    Display: "AMEX",
    Value: "AMEX",
  },
  {
    Display: "Barter",
    Value: "Barter",
  },
  {
    Display: "Cash",
    Value: "Cash",
  },
  {
    Display: "Check",
    Value: "Check",
  },
  {
    Display: "Master Card",
    Value: "Master Card",
  },
  {
    Display: "Discover",
    Value: "Discover",
  },
  {
    Display: "Visa",
    Value: "Visa",
  }
].sort((a, b) => a.Display.localeCompare(b.Display));

export const tieredCalucaltions = (quantity, pricingModelList) => {
  var DefinedPriceValue = 0;
  for (let pricingModel of pricingModelList) {
      let { startUnit, endUnit, Amount } = pricingModel;
      if (startUnit <= quantity && endUnit === -1) {
          DefinedPriceValue += Amount * (quantity - startUnit + 1);
          break;
      } else if (endUnit < quantity) {
          DefinedPriceValue += Amount * (endUnit - startUnit + 1);
          if (endUnit === quantity) break;
      } else if (startUnit <= quantity && endUnit >= quantity && endUnit != -1) {
          DefinedPriceValue += Amount * (quantity - startUnit + 1);
          break;
      }
  }
  return DefinedPriceValue
}

export const volumeCalucaltions = (quantity, pricingModelList) => {
  var DefinedPriceValue = 0;
  for (let pricingModel of pricingModelList) {
      let { startUnit, endUnit, Amount } = pricingModel;
      if (startUnit <= quantity && endUnit === -1) {
          DefinedPriceValue += Amount * quantity;
          break;
      } else if (startUnit <= quantity && endUnit >= quantity && endUnit != -1) {
          DefinedPriceValue += Amount * quantity;
          break;
      }
  }
  return DefinedPriceValue
}

export const stairStepCaluclations = (quantity, pricingModelList) => {
  var DefinedPriceValue = 0;
  for (let pricingModel of pricingModelList) {
      let { startUnit, endUnit, Amount } = pricingModel;
      if (startUnit <= quantity && endUnit === -1) {
          DefinedPriceValue = Amount;
          break;
      } else if (startUnit <= quantity && endUnit >= quantity && endUnit != -1) {
          DefinedPriceValue = Amount;
          break;
      }
  }
  return DefinedPriceValue
}

export function checkIsDigital(variable,noDecode=false) {
  var values;
  var substrings;
 
  if(noDecode){
    substrings = variable.split(",");
    values = substrings.map(substring => substring.split('#')[0]);
  }
  else{
    substrings = getValueFromIcode(variable).split(",");
    values = substrings.map(substring => substring.split('#')[0]);
  }
  
  for (var i = 0; i < values.length; i++) {
    if (values[i].trim() !== "3") {
      return false;
    }
  }
  return true;
}

export const dateFilters = [
  { Value: "Today", Display: "Today" },
  { Value: "Tomorrow", Display: "Tomorrow" },
  { Value: "Last Week", Display: "Last Week" },
  { Value: "This Week", Display: "This Week" },
  { Value: "Next Week", Display: "Next Week" },
  { Value: "Last Month", Display: "Last Month" },
  { Value: "This Month", Display: "This Month" },
  { Value: "Next Month", Display: "Next Month" },
];

export const defaultPagerData = {
  pageSize: 50,
  currentPageIndex: 1,
  currentPageTotal: 0,
  total: 0,
  isPageIndexChanged: false,
};

const textMeasureHelperCanvas = document.createElement('canvas');
const helperContext = textMeasureHelperCanvas.getContext('2d');

export const gridWidthAdjustment = (columnHeaders,totalWidth,setColumns,fixedWidthColumns = [],groupByColumn,StaticWidthCols=[]) => {
  const totalColumns =  columnHeaders.length;
  let restofWidthWithFixedColumns = totalWidth;
  let tempAccessors = [...columnHeaders.map((ele)=>ele.accessor)];
  let FixedWidthColumnsCopy=[...fixedWidthColumns.filter((col)=>tempAccessors.includes(col.accessor))]
  let StaticWidthColumns = [...StaticWidthCols.filter((col)=>tempAccessors.includes(col))]
  let fixedWidthColumnsAccessors=[...FixedWidthColumnsCopy.map((ele)=>ele.accessor)]
  FixedWidthColumnsCopy.forEach((column)=>{
    restofWidthWithFixedColumns-=column.width;
  })
  let restofWidth = restofWidthWithFixedColumns ;

  const data = columnHeaders.map((column,index) => {
    let cleanText = column?.Header?.trim();
    let charactersLength = cleanText?.length;
    let widthOfCell = 0;
    if(FixedWidthColumnsCopy.length !== 0 && fixedWidthColumnsAccessors?.includes(column.accessor)){
      widthOfCell = FixedWidthColumnsCopy[fixedWidthColumnsAccessors.indexOf(column.accessor)].width; // taking the width from the fixedWidthColumns array if the column is present in the fixedWidthColumns array
    }
    else if ( totalColumns>5 && (StaticWidthColumns.length !== 0 && StaticWidthColumns?.includes(column.accessor)) ) { // taking the width as 250 for standard width columns only if total columns is greater than 5 columns.
      widthOfCell = 250;
      restofWidth = restofWidth - widthOfCell;
    }else{
     if (charactersLength >= 12) { // if the characters are more than 12 then we are limiting the characters to 12
        cleanText = cleanText.substring(0, 12); // limits the characters to 12
      } else if (charactersLength <= 4) {
        cleanText = cleanText.padEnd(4, ' '); // adds spaces to the end of the text to make it 4 characters long if the characters are less than 4
      }
      widthOfCell = helperContext.measureText(cleanText).width + 50+13; // adding 50 to the width of the cell to make it look good
      restofWidth = restofWidth - (groupByColumn!==column.accessor?widthOfCell:0)  // calculates the rest of the width after deducting the width of the text from the total width
    }
    return {
      ...column,
      width: widthOfCell
    };
  });

  if(restofWidth <= 0){
    setColumns(data);
  }
  else{
    let infinityCheck=totalColumns-(groupByColumn?1:0)-FixedWidthColumnsCopy.length-(totalColumns>5 ?StaticWidthColumns.length:0);

    let extraWidth= restofWidth/(infinityCheck==0?StaticWidthColumns.length:infinityCheck);
    let columnsData = [...data.map((col)=>{
      let tempWidth=col.width
      if(fixedWidthColumnsAccessors.indexOf(col.accessor)<0 && StaticWidthColumns.indexOf(col.accessor)<0){
        tempWidth = tempWidth + extraWidth
      }
      else if(totalColumns<=5 && StaticWidthColumns.indexOf(col.accessor)>=0){
        tempWidth = tempWidth + extraWidth
      }
      else if(!infinityCheck){
        tempWidth = tempWidth + extraWidth
      }
      return {...col,width:tempWidth}
    })]
    setColumns(columnsData)
  }
}

export const extractFirstPageFromPdfFile = (file) => {
  return  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  }).then((dataUrl) => {
    return extractFirstPageFromDataURL(dataUrl);
  });
};

export const extractFirstPageFromDataURL= async (dataURL) => {
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');
  try {
    const pdfData = atob(dataURL.split(',')[1]); // Decode the base64 data part of the URL
    const pdfArray = new Uint8Array(pdfData.length);
    for (let i = 0; i < pdfData.length; i++) {
      pdfArray[i] = pdfData.charCodeAt(i);
    }

      // Load the PDF from the Uint8Array
      const pdf = await pdfjs.getDocument(pdfArray, {
        disableWorker: true,
        disableAutoFetch: true,
      }).promise;

    // Render the first page and extract it as an image
      try {
        const page = await pdf.getPage(1); // Extract the first page (page number is 1-based)
        const viewport = page.getViewport({ scale: 1 });

        const canvasWidth = 150;
        const canvasHeight = 220;
        const scaleX = canvasWidth / viewport.width;
        const scaleY = canvasHeight / viewport.height;
        const scale = Math.max(scaleX, scaleY);
        const newViewport = page.getViewport({ scale });

        // Set the canvas dimensions to match the fixed size
        canvas.width = newViewport.width;
        canvas.height = newViewport.height;
        
        
        
        //canvas.width = 1000;
        //canvas.height = 1500;
        
        await page.render({ canvasContext, viewport : newViewport }).promise;
  
        // Convert canvas to data URL representing the image
        const imageDataURL = canvas.toDataURL('image/jpeg');
        return {
          canvas,
          imageDataURL,
        };
        // You can now upload the imageDataURL to S3 or display it in your application as needed
      } catch (error) {
        console.error('Error rendering canvas:', error);
      }
    
  } catch (error) {
    console.error('Error extracting page from data URL:', error);
  }
};

export const authDetails = {
  REACT_APP_GCID : "ekXAztBploE9PVYOSVxKYag/ds10lyBkZtCKsKWiGy1cpYH+jHWyAotZiJDdQGJTiWRMY71zUsj4ONcdPbkV8EaC+MxnQNCOAASby5PZmw4=",
  REACT_APP_GCSID : "ISul+MAIZEfpZGiN29VFuu+xZZIRA28GvhOYIvujex8=",
  REACT_APP_OCID : "7IwVfR16IJvMu2m89Ta0z/62l8lRaEG9KcXQVTzkth2By6FFGxcVVw=="
}

export const isTimeSet = (dateObject) => {
  // Check if hours, minutes, seconds, or milliseconds are non-zero
  return (
      dateObject.getHours() !== 0 ||
      dateObject.getMinutes() !== 0 ||
      dateObject.getSeconds() !== 0 ||
      dateObject.getMilliseconds() !== 0
  );
}

//for removing encrypted html characters
export const decodeString = (str) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  txt.innerHTML = txt.textContent;
  return txt.textContent;
};

export const convertTo12HourFormat = (timeString) => {
  var date = new Date('2000-01-01T' + timeString);
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
}

// for renaming the original uploaded file from production note to filename convention
export const createRenamedFileName=(maximumNumber, idx, fileName, notesFileNameConvention)=> {
  const incrementedFileName = `${maximumNumber + (idx + 1)}.${fileName.split('.').pop()}`;
  const notesFileNameConvention_upper=notesFileNameConvention?.toUpperCase();
  const renamedFileName = `${notesFileNameConvention_upper}_${incrementedFileName}`;
  return renamedFileName;
};

export const AllProductType = {
  IsEnabled: true,
  IsNew: true,
  IsNewJobJacket: true,
  Description: "All Products",
  SortOrder: 999,
  SubProductTypeID: 999,
  gsPublicationID: 0,
  Action: null,
  TaskLabel: "Task",
  ParentId: 999,
  IsContactMandatory: false,
  IsContactEditSection: false,
  CanHaveIssues: true,
  CanAddProducts: false,
  ID: 999,
  Name: "All Products",
  ProductType: "IssueBased",
  Code: "AllProducts",
};

export const IsAllProductType = (productTypeId) => {
  return Number(productTypeId) === AllProductType.SubProductTypeID;
};

export const colorCodesForCalendar = ["#1CBB9B" , "#3598DB", "#9B58B5", "#F39C11","#26C96B", "#E84C3D", "#C72BB7", "#916AFF", "#5F96F3", "#A8AC1C", "#354A5F"];

// ************************ Mention Helper Functions Start ************************

// Function to format notes by replacing @mentions with styled HTML spans
    export const formatNoteContent = (note) => {
      // Replace mentions formatted as @[name](id) with a styled span element
      return note?.replace(/\@\[(.+?)\]\(\d+\)/g, (_, name) => {
        // Return the formatted HTML with the name inside a span
        return `<span class="highlight-mention">@${name}</span>`;
      });
    };

  export const mentionDefaultStyles = {
    control: {
      fontSize: 14,
      maxHeight: 100,
      border: "1px solid #dfdfdf",
      borderRadius:"3px"
    },

    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 100,
      },
      highlighter: {
        padding: "0 10px",
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        border: "1px solid silver",
        overflow: "scroll",
      },
    },

    suggestions: {
      list: {
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "1px",
        "&focused": {
          backgroundColor: "#689CE2",
          transition: "all 0.5s",
        },
      },
    },
  };

// ************************ Mention Helper Functions End ************************